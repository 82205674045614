import type { User } from "@supabase/supabase-js";
import React from "react";
import { useEffect, useState } from "react";
import { supabase } from "../lib/api";
import TodoItem from "./TodoItem";
import ArianaBackgroundSlider from "./ArianaBackgroundSlider";
import { ITodo } from "./types";

const Home = ({ user }: { user: User }) => {
    const [coupons, setCoupons] = useState<ITodo[]>([]);
    const [imageSrcs, setImageSrcs] = useState<(string | undefined)[]>([]);
    const [errorText, setError] = useState<string | null>("");

    console.log(coupons);

    const fetchTodos = async () => {
        let { data: coupons, error } = await supabase
            .from("coupons")
            .select("*")
            .order("id", { ascending: false });
        if (error) console.log("error", error);
        else setCoupons(coupons as ITodo[]);
    };

    useEffect(() => {
        fetchTodos();
    }, []);

    const fetchCouponsImage = async (src: string, i: number) => {
        let { data, error } = await supabase.storage
            .from("coupons-images")
            .getPublicUrl(src);
        setImageSrcs((srcs) => {
            srcs[i] = data?.publicURL;
            return [...srcs];
        });
        if (error) console.log("error", error);
    };

    useEffect(() => {
        console.log("load coupons", coupons);
        if (coupons.length) {
            coupons.forEach((coupon, i) => {
                fetchCouponsImage(coupon.image_src, i);
            });
        }
    }, [coupons]);

    const handleLogout = async () => {
        supabase.auth.signOut().catch(console.error);
    };

    return (
        <>
            <ArianaBackgroundSlider />
            <div className="w-screen fixed flex flex-col min-h-screen">
                <header className="flex justify-between items-center px-4 h-16 bg-gray-900">
                    <span className="text-2xl sm:text-4xl text-white font-mono">
                        Coupons for Arianka
                    </span>
                    <button
                        onClick={handleLogout}
                        className="flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                    >
                        Logout
                    </button>
                </header>
                <div
                    className="flex flex-wrap grow basis-0 justify-around items-center py-20 px-10 max-w-6xl m-auto gap-5 overflow-auto"
                    style={{ flexGrow: 1, flexBasis: 0 }}
                >
                    {coupons.map((coupon, i) => (
                        <TodoItem
                            key={coupon.id}
                            coupon={coupon}
                            image_src={imageSrcs[i]}
                        />
                    ))}
                </div>
                {!!errorText && (
                    <div
                        className={
                            "border max-w-sm self-center px-4 py-2 mt-4 text-center text-sm bg-red-100 border-red-300 text-red-400"
                        }
                    >
                        {errorText}
                    </div>
                )}
            </div>
        </>
    );
};

export default Home;
