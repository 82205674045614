import type { Provider } from "@supabase/supabase-js";
import * as React from "react";
import { supabase } from "../lib/api";
import ArianaBackgroundSlider from "./ArianaBackgroundSlider";

const Auth = () => {
    const handleOAuthLogin = async (provider: Provider) => {
        // You need to enable the third party auth you want in Authentication > Settings
        // Read more on: https://supabase.com/docs/guides/auth#third-party-logins
        let { error } = await supabase.auth.signIn({ provider });
        if (error) console.log("Error: ", error.message);
    };

    const queryParameters = new URLSearchParams(window.location.search);
    const error = queryParameters.get("error");

    return (
        <>
            <ArianaBackgroundSlider />
            <div className="z-20 w-full h-full sm:h-auto sm:w-2/5 max-w-sm p-5 bg-light-orange shadow flex flex-col text-base rounded-lg">
                <span className="text-2xl border-b font-sans text-center mb-3 font-bold text-white">
                    LOGIN
                </span>
                <div className="mt-3 mb-3">
                    <button
                        onClick={() => handleOAuthLogin("google")}
                        type="button"
                        className="w-3/4 mx-auto flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-400 focus:outline-none focus:border-blue-600 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                    >
                        Google
                    </button>
                </div>
                {error === "access_denied" && (
                    <span className="text-xl font-bold text-center text-red-600">
                        Access Denied!
                    </span>
                )}
            </div>
        </>
    );
};

export default Auth;
