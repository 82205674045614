import React from "react";
import { Fragment, useState } from "react";
import { supabase } from "../lib/api";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ITodo } from "./types";

const TodoItem = ({
    coupon,
    image_src,
}: {
    coupon: ITodo;
    image_src?: string;
}) => {
    const [isClaimed, setIsClaimed] = useState(coupon.claimed);

    const toggleClaimed = async () => {
        if (isClaimed) return;
        const { data, error } = await supabase
            .from("coupons")
            .update({ claimed: true })
            .eq("id", coupon.id)
            .single();
        if (error) {
            console.error(error);
        } else {
            setIsClaimed(data.claimed);
        }
    };

    const [open, setOpen] = useState(false);

    const handleOnClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
        event.preventDefault();
        setOpen(true);
    };

    return (
        <>
            {
                <div
                    className={
                        "group relative" +
                        (isClaimed ? " opacity-40 cursor-not-allowed" : "")
                    }
                    onClick={handleOnClick}
                >
                    <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-md aspect-none group-hover:opacity-75 h-80">
                        <img
                            src={image_src}
                            alt={coupon.name}
                            className={
                                "h-full w-full object-cover object-center"
                            }
                        />
                    </div>
                    <div className="mt-2 flex justify-around bg-white rounded-md group-hover:opacity-75 outline outline-offset-2 outline-blue-500">
                        <div>
                            <h3 className="text-sm text-black font-semibold">
                                <span aria-hidden="true" />
                                {coupon.name}
                            </h3>
                        </div>
                    </div>
                </div>
            }
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                                enterTo="opacity-100 translate-y-0 md:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 md:scale-100"
                                leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            >
                                <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                                    <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                                        <button
                                            type="button"
                                            className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                                            onClick={() => setOpen(false)}
                                        >
                                            <span className="sr-only">
                                                Close
                                            </span>
                                            <XMarkIcon
                                                className="h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        </button>

                                        <div className="grid w-full h-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8">
                                            <div className="aspect-h-3 aspect-w-2 overflow-hidden rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5">
                                                <img
                                                    src={image_src}
                                                    alt={coupon.name}
                                                    className={
                                                        "object-cover object-center" +
                                                        (isClaimed
                                                            ? " opacity-40 cursor-not-allowed"
                                                            : "")
                                                    }
                                                />
                                            </div>
                                            <div className="flex flex-col h-full justify-between sm:col-span-8 lg:col-span-7">
                                                <div>
                                                    <h2 className="text-2xl font-bold text-gray-900 sm:pr-12">
                                                        {coupon.name}
                                                    </h2>

                                                    <h3 className="mt-4 text-xl text-gray-900 sm:pr-12 whitespace-pre-line">
                                                        {coupon.desc}
                                                    </h3>
                                                </div>

                                                <section
                                                    aria-labelledby="options-heading"
                                                    className="mt-10"
                                                >
                                                    <button
                                                        disabled={isClaimed}
                                                        onClick={toggleClaimed}
                                                        className={
                                                            "flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" +
                                                            (isClaimed
                                                                ? " opacity-40 cursor-not-allowed"
                                                                : "")
                                                        }
                                                    >
                                                        {isClaimed
                                                            ? "CLAIMED"
                                                            : "CLAIM"}
                                                    </button>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};

export default TodoItem;
