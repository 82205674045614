import React from "react";
import BackgroundSlider from "./BackgroundSlider/BackgroundSlider";

const images = require.context("../img", false);
const imageList = images.keys().map((image: any) => images(image).default);
console.log(imageList, "ASDASD");

const ArianaBackgroundSlider = () => {
    return (
        <div className="fixed w-full h-full">
            <BackgroundSlider
                images={imageList}
                duration={3}
                transition={0.5}
            />
            <div className="fixed min-w-full min-h-full z-10 bg-black bg-opacity-70"></div>
        </div>
    );
};

export default ArianaBackgroundSlider;
